<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headerType}} الفترات الزمنية
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="brnadForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >

          <!--  key -->
          <validation-provider
            #default="validationContext"
            name="period"
            rules="required"
          >
            <b-form-group
              label="الفترة"
              label-for="period"
            >
              <b-form-input
                id="period"
                v-model="period"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.period">{{ allerrors.period[0] }}</span>
            </b-form-group>
          </validation-provider>

         
         
       
  
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }}
            </b-button>
            <!-- spinner -->
            <b-button
              v-if="loading"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              إلغاء
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { watch } from '@vue/composition-api'
import DataService from './data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import md5 from 'js-md5'

export default {
  
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSlider: {
      type: Object,
      default: () => {}
    },
    
    
  },
  data() {
    return {
      required,
      alphaNum,
      period,
      headerType: '',
      isAddPasswordMode: false,
      // isAddNewUserSidebarActive: false,
      key:'',
      value_data:'',
      id: '',
      loading: false,
      allerrors: [],
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      console.log("newVal:", val, "Type:", typeof val);
      if (typeof val !== 'boolean') {
        throw new Error("input is invalid type");
      }

      if(!val) return
      if(Object.entries(this.dataSlider).length === 0) {
        this.headerType = 'إضافة'
        // this.isAddPasswordMode = true
        this.initValues()
      }else {        
        this.headerType = 'تعديل'
        // this.isAddPasswordMode = false
        // console.log(this.dataSlider)
        this.period       = this.dataSlider.period

        // this.initValues()
      }
    }
  },
  methods: {
    initValues() {
      if(this.dataSlider.id) return
        this.period       = null
        this.allerrors = []
    },
    prepareData(){
      
      return {
        period: this.period,
      }
    },
    onSubmit(){
      // if(!this.id){
        this.sendData()
      // }else{
        // this.editData();
      // }
    },
    sendData(){
      this.loading = true;
      this.allerrors = []
     
        // console.log(this.prepareData());

        DataService.create(this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.status
        if(responseCode == 204){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "مزودي الخدمات",
              icon: 'EditIcon',
              variant: 'success',
              text: "تم اﻹضافة بنجاح"
            },
          })
          this.$emit('update:is-add-new-user-sidebar-active', false)
            
          var arr_data = [];

          DataService.getAll(1)
            .then(response => {
              for (const [key, value] of Object.entries(response.data)) {
                  // console.log(`Key: ${key}, Value: ${value}`);
                  var obj = {};
                  // obj['key'] = `${key}`;
                  obj['period'] = `${value}`;

                  arr_data.push(obj);

              }

              this.items = arr_data
              console.log(this.items)

              this.$emit('refreshData',this.items)
            })   
           
          }else if(responseCode === 101){
            console.log('error');
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
   
   
    
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>