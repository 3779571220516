import axios from '@/libs/axios'

class DataService {
  getAll(page) {
    return axios.get(`/time`);
  }
 
  delete(period) {
    return axios.delete(`/time?period=${period}`);
  }

  create(data) {
    console.log(data.period);
    return axios.post(`/time?period=${data.period}`);
  }
}
export default new DataService();